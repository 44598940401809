import React from 'react';
import NavBar from '../../../../components/NavBar';

/*
 This component is used for the Quest header if "showHeader" is set to true
*/
export default () => {
    return (
        <div style={{ marginBottom: '-1.35em' }}>
            <NavBar />
        </div>
    );
};
