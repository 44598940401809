import React from 'react';
import PropTypes from 'prop-types';
import axios from 'cccisd-axios';
import IconArrowLeft from 'cccisd-icons/arrow-left6';
import IconCheckmark from 'cccisd-icons/checkmark';
import IconUser from 'cccisd-icons/user3';
import { LoginForm, ProfileUpdateForm } from 'cccisd-laravel-nexus';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import NavLink from '../NavLink';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

export default class CustomBoilerplateNavBar extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        logo: PropTypes.node,
        children: PropTypes.node,
        noLogoLink: PropTypes.bool,
        showMenu: PropTypes.bool,
        allowSwitchRoles: PropTypes.bool,
        hideSeparateRoleSwitcher: PropTypes.bool,
        showTheseProfileDataFields: PropTypes.object, // array of data field handles to include in profile update
        renderInModal: PropTypes.bool,
        logoLinkTo: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
        logo: 'Logo',
        noLogoLink: false,
        showMenu: true,
        allowSwitchRoles: true,
        renderInModal: true,
    };

    state = {
        loading: false,
    };

    returnToUser = async e => {
        e && e.preventDefault();

        this.setState({ loading: true });
        await axios.get(Boilerplate.route('api.nexus.user.logoutAs'));
        window.location.href = Boilerplate.url(Fortress.settings.config.after_login_url);
    };

    changeRole = async (pawnId, e) => {
        e && e.preventDefault();

        if (pawnId === Fortress.user.pawnId) {
            return;
        }

        this.setState({ loading: true });
        const response = await axios.post(Boilerplate.route('api.nexus.user.changeTo'), {
            pawn: pawnId,
        });
        if (response.status !== 200 || !response.data.data) {
            return;
        }
        let redirectUrl = Boilerplate.url(Fortress.settings.config.after_login_url);
        if (response.data.data.hasIncompleteSiteRegistration) {
            redirectUrl = Boilerplate.url('/account/registrationSurvey');
        }
        window.location.href = redirectUrl;
    };

    openLogInModal = e => {
        e && e.preventDefault();
        this.modalLogIn.open();
    };

    openUpdateProfileModal = e => {
        e && e.preventDefault();
        this.modalUpdateProfile.open();
    };

    openChangePasswordModal = e => {
        e && e.preventDefault();
        this.modalChangePassword.open();
    };

    render() {
        const adminMenuItems = [];
        const menuItemsLeft = [];
        const menuItems = [];
        let returnToUserLink = null;
        const showAdminMenu =
            this.props.showMenu && Fortress.auth() && Fortress.user.acting.is_super_user;

        if (Fortress.auth()) {
            let switchRoles = Fortress.user.pawns.filter(
                r => !['Guest', 'Respondent'].includes(r.name)
            );
            if (Fortress.user.loggedInAsAnotherUser) {
                returnToUserLink = (
                    <li key="returnToUserLink">
                        <a href="#" onClick={this.returnToUser}>
                            <IconArrowLeft spaceRight /> Return to &quot;
                            {Fortress.user.actualUsername}&quot; user
                        </a>
                    </li>
                );
            }

            if (Fortress.hasAccess('uber.admin')) {
                adminMenuItems.push(
                    <li key="legosAdmin" className="dropdown">
                        <a
                            href="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            ADMIN <span className="caret" />
                        </a>
                        <ul className="dropdown-menu">
                            <NavLink to="/appdefs/about">About</NavLink>
                            <NavLink to="/appdefs/logs">Logs</NavLink>
                            <li role="separator" className="divider" />
                            <NavLink to="/quest">Quests</NavLink>
                            <NavLink to="/resources/admin">Resources</NavLink>
                            <NavLink to="/shorturls">Short URLs</NavLink>
                            <li role="separator" className="divider" />
                            <NavLink to="/appdefs/admin/json-editor">App Settings</NavLink>
                        </ul>
                    </li>
                );
            }

            if (
                this.props.allowSwitchRoles &&
                !this.props.hideSeparateRoleSwitcher &&
                !Fortress.user.loggedInAsAnotherUser &&
                switchRoles.length > 1
            ) {
                menuItems.push(
                    <li key="switchProfile" className="dropdown pull-right">
                        <a
                            href="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {Fortress.user.acting.label} <span className="caret" />
                        </a>
                        <ul className="dropdown-menu">
                            {switchRoles.map(pawn => (
                                <li key={pawn.id}>
                                    <a href="#" onClick={this.changeRole.bind(this, pawn.id)}>
                                        {pawn.name}
                                        {pawn.id === Fortress.user.pawnId && (
                                            <IconCheckmark spaceLeft />
                                        )}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </li>
                );
            }

            menuItems.push(
                <li key="logout" className="dropdown">
                    <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <IconUser spaceRight />
                        {Fortress.user.username} <i className="caret" />
                    </a>
                    <ul className="dropdown-menu">
                        {this.props.allowSwitchRoles &&
                            !Fortress.user.loggedInAsAnotherUser &&
                            switchRoles.map(pawn => (
                                <li key={pawn.id}>
                                    <a href="#" onClick={this.changeRole.bind(this, pawn.id)}>
                                        {pawn.name}
                                        {pawn.id === Fortress.user.pawnId && (
                                            <IconCheckmark spaceLeft />
                                        )}
                                    </a>
                                </li>
                            ))}
                        {this.props.allowSwitchRoles && !Fortress.user.loggedInAsAnotherUser && (
                            <li role="separator" className="divider" />
                        )}
                        {!Fortress.user.loggedInAsAnotherUser &&
                            Fortress.user.user.auth_type !== 'ldap' && [
                                <li key="updateProfile">
                                    <a href="#" onClick={this.openUpdateProfileModal}>
                                        Update Profile
                                    </a>
                                </li>,
                                <li key="separator1" role="separator" className="divider" />,
                                <li key="changePassword">
                                    <a href="#" onClick={this.openChangePasswordModal}>
                                        Change Password
                                    </a>
                                </li>,
                                <li key="separator2" role="separator" className="divider" />,
                            ]}

                        <li key="logoutLink">
                            <a href={Boilerplate.url('/api/user/logout')}>Log out</a>
                        </li>
                    </ul>
                </li>
            );
        } else {
            this.props.renderInModal
                ? menuItemsLeft.push(
                      <li key="login">
                          <a href="#" onClick={this.openLogInModal}>
                              LOG IN
                          </a>
                      </li>
                  )
                : menuItemsLeft.push(
                      <li key="login">
                          <a href={Boilerplate.url('/account/login')}>LOG IN</a>
                      </li>
                  );
        }

        return (
            <Loader loading={this.state.loading}>
                <Modal
                    trigger={null}
                    title="Log in"
                    size="small"
                    ref={elem => {
                        this.modalLogIn = elem;
                    }}
                >
                    <LoginForm formName="topLoginForm" />
                </Modal>
                <Modal
                    trigger={null}
                    title="Update Profile"
                    ref={elem => {
                        this.modalUpdateProfile = elem;
                    }}
                >
                    <ProfileUpdateForm
                        onSuccess={() => {
                            notification('Profile has been updated');
                            this.modalUpdateProfile.close();
                        }}
                        pawnId={Fortress.user.pawnId}
                        pawnHash={Fortress.user.actual.random_hash}
                        includeUserFields
                        includePawnFields
                        includePasswordFields={false}
                        showTheseProfileDataFields={this.props.showTheseProfileDataFields}
                    />
                </Modal>
                <Modal
                    trigger={null}
                    title="Change Password"
                    ref={elem => {
                        this.modalChangePassword = elem;
                    }}
                >
                    <ProfileUpdateForm
                        onSuccess={() => {
                            notification('Password has been successfully changed');
                            this.modalChangePassword.close();
                        }}
                        pawnId={Fortress.user.pawnId}
                        pawnHash={Fortress.user.actual.random_hash}
                        includeUserFields={false}
                        includePawnFields={false}
                        includePasswordFields
                        buttonLabel="Change Password"
                    />
                </Modal>
                <nav className="navbar navbar-default">
                    <div className={this.props.className}>
                        <div className="navbar-header">
                            <button
                                type="button"
                                className="navbar-toggle collapsed"
                                data-toggle="collapse"
                                data-target="#main-navbar-collapse"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                        </div>

                        <div
                            className="collapse navbar-collapse"
                            id="main-navbar-collapse"
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <ul className="nav navbar-nav">
                                {this.props.showMenu && menuItemsLeft.length > 0 && menuItemsLeft}
                                {this.props.children}
                            </ul>
                            <ul className="nav navbar-nav navbar-right">
                                {returnToUserLink}
                                {!!showAdminMenu && adminMenuItems}
                                {this.props.showMenu && menuItems}
                            </ul>
                        </div>
                    </div>
                </nav>
            </Loader>
        );
    }
}
