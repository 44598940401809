import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import CustomBoilerplateNavBar from '../CustomBoilerplateNavBar';
import NavItem from './NavItem.js';
import styles from './styles.css';
import logoImg from './logo.svg';

const AppDefs = window.cccisd.appDefs;

/*
 *  In Layouts, this component has been replaced with Laravel Header
 *  However, we still keep it in code because it is the header shown
 *  in Surveys if the "Show Header" option is selected
 */
const NavBar = props => {
    // const { className } = props;
    const className = 'container'; // WUSTL Folks always want container

    let navItems = [];
    const linksFromAppdefs = _get(
        AppDefs.navs.find(n => n.handle === 'mainNav'),
        'nav',
        []
    );
    const routesFromAppdefs = _get(AppDefs, 'routes', []);
    linksFromAppdefs.forEach(defItem => {
        let constructedItem = {
            handle: defItem.handle,
            render: defItem.render,
        };

        constructedItem.render.params.activeRegex = new RegExp(
            constructedItem.render.params.activeRegex
        );
        const matchingRoute = routesFromAppdefs.find(r => r.handle === defItem.handle);
        if (matchingRoute) {
            constructedItem.url = matchingRoute.url;
            navItems.push(constructedItem);
        }
    });

    return (
        <div className={styles.headerWrapper}>
            <div className={styles.branding}>
                <div className={className} style={{ padding: 0 }}>
                    <img
                        alt="Washington University School of Medicine in St. Louis"
                        className={styles.logo}
                        src={logoImg}
                    />
                </div>
            </div>
            <div className={classnames(className, styles.siteName)}>
                <Link to="/">CBT for Eating Disorders</Link>
            </div>
            <CustomBoilerplateNavBar {...props} className={className}>
                {navItems.length > 0 &&
                    navItems.map(item => (
                        <NavItem
                            key={item.handle}
                            item={item}
                            active={!!props.location.pathname.match(item.render.params.activeRegex)}
                            {...props}
                        />
                    ))}
            </CustomBoilerplateNavBar>
        </div>
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default withRouter(NavBar);
