import React from 'react';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Modal from 'cccisd-modal';
import FileText from 'cccisd-icons/file-text2';
import style from './style.css';

const PrintView = props => {
    const { row, hideText, icon } = props;
    const deploymentId = props.deploymentId || row['deployment.deploymentId'];
    const pawnId = props.pawnId || row['pawn.pawn.pawnId'];
    const pawnHash = props.pawnHash || row['pawn.pawn.pawnHash'];

    return (
        <Modal
            size="large"
            trigger={
                <button type="button" className="btn btn-primary">
                    {!hideText && (
                        <>
                            <FileText /> Report
                        </>
                    )}
                    {icon && icon}
                </button>
            }
        >
            <div className={style.fixContainer}>
                <DeploymentPlayer
                    deploymentId={deploymentId}
                    pawnId={pawnId}
                    pawnHash={pawnHash}
                    disableLayout
                    flowProps={{ isPrintMode: true }}
                />
            </div>
        </Modal>
    );
};

export default PrintView;
