import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
const hostName = window.location.hostname;

const Footer = props => {
    return (
        <div className={style.footer}>
            <div className="container">
                <div className={style.wrapper}>
                    <div className={style.marginBottom}>
                        <h5>CBT for Eating Disorders</h5>
                        <p>
                            Center for Healthy Weight and Wellness <br />
                            Department of Psychiatry <br />
                            Washington University School of Medicine <br />
                            4590 Children&apos;s Place, 2nd Floor Suite 2100 <br />
                            St. Louis, MO 63110 <br />
                            <a href="mailto:cbtforeatingdisorders@wustl.edu">
                                cbtforeatingdisorders@wustl.edu
                            </a>
                        </p>
                    </div>
                    <div className={style.marginBottom}>
                        <h4>FUNDING SOURCE</h4>
                        <p>
                            Center for Dissemination and Implementation <br />
                            Institute for Public Health at Washington University in St. Louis
                        </p>
                    </div>
                </div>
                <div className={style.marginBottom}>
                    <p>©2020 Washington University in St. Louis</p>
                </div>
                <hr />
                <div className={style.signature}>
                    <p>
                        Website and software developed by{' '}
                        <a
                            href={`//www.3cisd.com?r=${hostName}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            3C Institute
                        </a>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    showPolicyLinks: PropTypes.bool,
};

Footer.defaultProps = {
    className: 'container',
    showPolicyLinks: true,
};

export default Footer;
