import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Html } from 'cccisd-wysiwyg';
import styles from './styles.css';

const NavItem = ({ active, item }) => {
    function processUrl({ url, history }) {
        const found = url.match(/(\/:[\w-]+\?)/g);

        if (found) {
            found.forEach(pattern => {
                url = url.replace(pattern, '');
            });
        }
        history.push(url);
    }

    return (
        <li key={item.handle} className={active ? styles.active : ''}>
            <Route
                render={({ history }) => (
                    <a onClick={processUrl.bind(null, { url: item.url, history })}>
                        {item.render.params.icon && (
                            <span className={`${styles.icon} icon`}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox={item.render.params.icon.viewBox}
                                >
                                    <g>
                                        {item.render.params.icon.paths.map(p => (
                                            <path key={p} d={p} />
                                        ))}
                                    </g>
                                </svg>
                            </span>
                        )}
                        <span className="secondary-nav-text">
                            <Html content={item.render.params.label} />
                        </span>
                    </a>
                )}
            />
        </li>
    );
};

NavItem.propTypes = {
    active: PropTypes.bool,
    item: PropTypes.object,
    location: PropTypes.object,
};

export default NavItem;
