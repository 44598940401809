import React from 'react';
import TableTop from 'cccisd-tabletop';
import Tooltip from 'cccisd-tooltip';
import PrintView from './PrintView';
import instructorQuery from './instructorQuery.graphql';
import learnerQuery from './learnerQuery.graphql';
import InstructorButtons from './InstructorButtons';
import style from './style.css';

const Fortress = window.cccisd.fortress;

const Dashboard = () => {
    const isInstructor = Fortress.user.acting.role.handle === 'instructor';
    const pawnId = Fortress.user.acting.id;
    let tableTop = React.createRef();

    const renderHeader = props => {
        const currentView = tableTop.current && tableTop.current.getCurrentView();
        const title = currentView && currentView.title;

        return (
            <>
                {title && (
                    <h3 className={isInstructor ? style.instructorTitle : style.title}>{title}</h3>
                )}
                {props.renderDefault()}
            </>
        );
    };

    const learnerViews = [
        {
            handle: 'selfMonitoring',
            title: 'Self-Monitoring of Eating',
            table: {
                query: learnerQuery,
                rowKey: 'deployment.deploymentId',
                render: renderHeader,
                graphqlVariables: {
                    pawnId,
                    assignmentId: 2,
                },
                orderBy: 'responseSet',
                isAscentOrder: false,
                columns: [
                    {
                        name: 'responseSet',
                        label: 'Submission',
                        className: 'text-center',
                        width: '20%',
                        filter: true,
                        sort: true,
                        render: ({ row }) => {
                            return row['deployment.timepoint'];
                        },
                    },
                    {
                        name: 'devTags.SelfMonitorDate',
                        label: 'Monitor Date',
                        filterSettings: {
                            type: 'date',
                        },
                        width: '40%',
                        filter: true,
                        sort: true,
                    },
                    {
                        name: 'deployment.deploymentId',
                        label: 'Review',
                        className: 'text-center',
                        render: ({ value, row }) => {
                            return (
                                <Tooltip title="View Responses">
                                    <PrintView row={row} />
                                </Tooltip>
                            );
                        },
                    },
                ],
            },
        },
        {
            handle: 'symptomTracking',
            title: 'Symptom Tracking',
            table: {
                query: learnerQuery,
                rowKey: 'deployment.deploymentId',
                render: renderHeader,
                graphqlVariables: {
                    pawnId,
                    assignmentId: 1,
                },
                orderBy: 'responseSet',
                isAscentOrder: false,
                columns: [
                    {
                        name: 'responseSet',
                        label: 'Submission',
                        className: 'text-center',
                        width: '20%',
                        filter: true,
                        sort: true,
                        render: ({ row }) => {
                            return row['deployment.timepoint'];
                        },
                    },
                    {
                        name: 'completedDate',
                        label: 'Completed Date',
                        filterSettings: {
                            type: 'date',
                        },
                        width: '40%',
                        filter: true,
                        sort: true,
                    },
                    {
                        name: 'deployment.deploymentId',
                        label: 'Review',
                        className: 'text-center',
                        render: ({ value, row }) => {
                            return (
                                <Tooltip title="View Responses">
                                    <PrintView row={row} />
                                </Tooltip>
                            );
                        },
                    },
                ],
            },
        },
    ];
    const instructorViews = [
        {
            handle: 'selfMonitoring',
            title: 'Patient Data',

            table: {
                query: instructorQuery,
                render: renderHeader,
                rowKey: 'pawn.pawnId',
                columns: [
                    {
                        name: 'user.firstName',
                        label: 'First Name',
                        filter: true,
                        sort: true,
                    },
                    {
                        name: 'user.lastName',
                        label: 'Last Name',
                        filter: true,
                        sort: true,
                    },
                    {
                        name: 'user.username',
                        label: 'User Name',
                        filter: true,
                        sort: true,
                    },
                    {
                        name: 'user.lastLoginDate',
                        label: 'Last Login',
                        filterSettings: {
                            type: 'date',
                        },
                        filter: true,
                        sort: true,
                    },
                    {
                        name: 'deployment.deploymentId',
                        label: 'Review',
                        render: ({ value, row }) => {
                            return <InstructorButtons row={row} learnerViews={learnerViews} />;
                        },
                    },
                ],
            },
        },
    ];
    const views = isInstructor ? instructorViews : learnerViews;

    return (
        <div>
            <TableTop ref={tableTop} handle="learners" views={views} />
        </div>
    );
};

export default Dashboard;
