import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

const NavLink = ({ to, children, location }) => {
    return (
        <li className={location.pathname.startsWith(to) ? 'active' : ''}>
            <Link to={to}>{children}</Link>
        </li>
    );
};

NavLink.propTypes = {
    location: PropTypes.object,
    to: PropTypes.string,
    children: PropTypes.node,
};

export default withRouter(NavLink);
