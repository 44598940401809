import React from 'react';
import Table from 'cccisd-graphql-table';
import Modal from 'cccisd-modal';
import ForkKnife from 'cccisd-icons/fork-knife';
import BarChart from 'cccisd-icons/stats-bars2';
import PieChart from 'cccisd-icons/pie-chart';
import Tooltip from 'cccisd-tooltip';
import PrintView from '../PrintView';
import learnerQuery from '../learnerQuery.graphql';

const InstructorButtons = props => {
    const { row, learnerViews } = props;
    const pawnId = row['pawn.pawnId'];
    const pawnHash = row['pawn.pawnHash'];

    const buttons = {
        selfMonitoring: {
            text: 'Self Monitoring of Eating',
            icon: <ForkKnife />,
            columns: learnerViews[0].table.columns,
            assignmentId: 2,
            orderBy: learnerViews[0].table.orderBy,
            isAscentOrder: learnerViews[0].table.isAscentOrder,
        },
        symptomTracking: {
            text: 'Symptom Tracking',
            icon: <BarChart />,
            columns: learnerViews[1].table.columns,
            assignmentId: 1,
            orderBy: learnerViews[1].table.orderBy,
            isAscentOrder: learnerViews[1].table.isAscentOrder,
        },
        registration: {
            text: 'Registration Survey',
            icon: <PieChart />,
            deploymentId: 2,
        },
    };

    const renderTableButton = button => {
        const { text, icon, columns, assignmentId, orderBy, isAscentOrder } = button;

        return (
            <Tooltip title={text}>
                <Modal
                    size="large"
                    title={text}
                    trigger={
                        <button type="button" className="btn btn-primary">
                            {icon}
                        </button>
                    }
                >
                    <Table
                        query={learnerQuery}
                        rowKey="deployment.deploymentId"
                        graphqlVariables={{ pawnId, assignmentId }}
                        columns={columns}
                        orderBy={orderBy}
                        isAscentOrder={isAscentOrder}
                    />
                </Modal>
            </Tooltip>
        );
    };

    const renderPreviewButton = () => {
        const { text, icon, deploymentId } = buttons.registration;

        return (
            <Tooltip title={text}>
                <PrintView
                    pawnId={pawnId}
                    pawnHash={pawnHash}
                    deploymentId={deploymentId}
                    icon={icon}
                    hideText
                />
            </Tooltip>
        );
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {renderTableButton(buttons.selfMonitoring)} {renderTableButton(buttons.symptomTracking)}
            {renderPreviewButton()}
        </div>
    );
};

export default InstructorButtons;
